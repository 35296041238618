.App {
  text-align: center;
}

.App-logo {
  width: 350px;
}

@media screen and (max-width: 60rem) {
  .App-logo {
    width: 200px;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.carousel-container {
  position: relative;
  overflow: hidden;
  margin: 0 auto; /* Centers the carousel horizontally */
  display: flex;
  align-items: center; /* Centers the content vertically */
  justify-content: center; /* Centers the content horizontally */
}

.slider-item {
  margin-bottom: 40px;
  border-radius: 15px; /* Slightly rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  max-width: 80vw;
  max-height: max-content;
  width: 60vw; /* Default width: 60% of the viewport width */
  height: 60vh; /* Default height: 60% of the viewport height */
  object-fit: cover; /* Ensures the image fills the container without distortion */
  border-radius: 15px; /* Slightly rounded corners */
}

@media (max-width: 768px) {
  .slider-item {
    max-width: 85; 
  }
}


@media (orientation: landscape) {
  .slider-item {
    max-width: 50vw; 
  }
}



.carousel .control-dots {
  bottom: 10px; /* Adjusts the dots position */
}

.carousel .control-dots .dot {
  box-shadow: none;
  text-shadow: none;
  background-color: #888; /* Dots default color */
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 5px;
}

.carousel .control-dots .dot.selected {
  background-color: #00a6ff; /* Dots color when selected */
}

.home-pic {
  filter: drop-shadow(0px 0.4rem 0.2rem rgba(0, 0, 0, 0.3));
  margin-bottom: 20px;
}

@media screen and (max-width: 60rem) {
  .slider-item {
    height: 40vh;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Trueno Light";
  src:
    local("Trueno Light"),
    url(../fonts/trueno-cufonfonts-webfont/TruenoLt.woff) format("woff");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Montserrat Italic";
  src:
    local("Montserrat Italic"),
    url(../fonts/Montserrat/static/Montserrat-MediumItalic.ttf)
      format("truetype");
}

@font-face {
  font-family: "Montserrat Regular";
  src:
    local("Montserrat Regular"),
    url(../fonts/Montserrat/static/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat Medium";
  src:
    local("Montserrat Medium"),
    url(../fonts/Montserrat/static/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat ExtraBold";
  src:
    local("Montserrat ExtraBold"),
    url(../fonts/Montserrat/static/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat SemiBold";
  src:
    local("Montserrat SemiBold"),
    url(../fonts/Montserrat/static/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat Light";
  src:
    local("Montserrat Light"),
    url(../fonts/Montserrat/static/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans Light";
  src:
    local("OpenSans Light"),
    url(../fonts/Open_Sans/static/OpenSans/OpenSans-Light.ttf)
      format("truetype");
}

@font-face {
  font-family: "OpenSans Regular";
  src:
    local("OpenSans Regular"),
    url(../fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "OpenSans Medium";
  src:
    local("OpenSans Medium"),
    url(../fonts/Open_Sans/static/OpenSans/OpenSans-Medium.ttf)
      format("truetype");
}

@font-face {
  font-family: "OpenSans SemiBold";
  src:
    local("OpenSans SemiBold"),
    url(../fonts/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf)
      format("truetype");
}

@font-face {
  font-family: "OpenSans Bold";
  src:
    local("OpenSans Bold"),
    url(../fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSans Italic";
  src:
    local("OpenSans Italic"),
    url(../fonts/Open_Sans/static/OpenSans/OpenSans-Italic.ttf)
      format("truetype");
}

@font-face {
  font-family: "OpenSans MediumItalic";
  src:
    local("OpenSans MediumItalic"),
    url(../fonts/Open_Sans/static/OpenSans/OpenSans-MediumItalic.ttf)
      format("truetype");
}

@font-face {
  font-family: "NotoSans Light";
  src:
    local("NotoSans Light"),
    url(../fonts/Noto_Sans/NotoSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSans Regular";
  src:
    local("NotoSans Regular"),
    url(../fonts/Noto_Sans/NotoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSans SemiBold";
  src:
    local("NotoSans SemiBold"),
    url(../fonts/Noto_Sans/NotoSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Manrope Regular";
  src:
    local("Manrope Regular"),
    url(../fonts/Manrope/static/Manrope-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Manrope Light";
  src:
    local("Manrope Light"),
    url(../fonts/Manrope/static/Manrope-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Alcefun";
  src:
    local("Alcefun"),
    url(../fonts/Alcefun.otf) format("truetype");
}

@font-face {
  font-family: "AC-Nixie";
  src:
    local("AC-Nixie"),
    url(../fonts/AC-Nixie.otf) format("truetype");
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
