.legal-container {
  margin: 140pt;
}

.legal-container span {
  color: #57606f;
}

@media screen and (max-width: 60rem) {
  .legal-container {
    margin-inline: 30pt;
  }
}

p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin: 0cm;
  line-height: 115%;
  font-size: 11pt;
}

.MsoChpDefault {
  font-size: 11pt;
}

.MsoPapDefault {
  line-height: 115%;
}

@page WordSection1 {
  size: 595.45pt 841.7pt;
  margin: 72pt 72pt 72pt 72pt;
}

ol {
  margin-bottom: 0cm;
}

ul {
  margin-bottom: 0cm;
}
