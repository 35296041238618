.value {
  font-family: "Trueno Light";
  font-size: 50px;
  color: #57606f;
}

.label {
  font-family: "OpenSans Regular";
  font-size: 10px;
  color: #57606f;
}
